import React, { useContext } from 'react'
import { Box, Container, Grid } from "@mui/material";
// import FeaturedSwiperImgOne from "../../assets/featured-swiper-img-1.png";
// import FeaturedSwiperImgTwo from "../../assets/featured-swiper-img-2.png";
// import FeaturedSwiperImgThree from "../../assets/featured-swiper-img-3.png";
import FeaturedOneImg from "../../assets/featured-one.jpg";
import FeaturedTwoImg from "../../assets/featured-two.jpg";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
// import FeaturedGrid from "./FeaturedGrid";
// import { getAllSliderItems } from '../ApiToFatch';
import AuthContext from '../../auth/AuthContext';

const FeatureProducts = () => {

    const { sliderItems } = useContext(AuthContext);

    // filtering items with status value = true only 
    const filteredSliderItems = sliderItems.filter(item => item.status);

    // Sort slider items by sequence
    filteredSliderItems.sort((a, b) => a.sequence - b.sequence);

    return (
        <>
            <Container sx={{ marginTop: { xs: '35px', md: '22px' } }}>
                <Box>
                    <Grid container justifyContent={'space-between'} className='maingridoflayout'>
                        <Grid item xs={12} md={8} sx={{ paddingRight: { xs: '0', md: '20px' }, height: { xs: '220px', sm: '256px', md: 'inherit' } }} className='secondgridoflayout'>
                            {filteredSliderItems.length > 0 ? (
                                <OwlCarousel
                                    className='owl-theme'
                                    loop
                                    items={1}
                                    dots={false}
                                    autoplayTimeout={2000}
                                    autoplay={true}
                                    style={{ height: '100%' }}
                                >
                                    {filteredSliderItems.map((item) => (
                                        <Box key={item.sequence} className='item' sx={{ height: '100%' }}>
                                            <Box
                                                className="container"
                                                sx={{
                                                    backgroundImage: `url(${item.image})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    padding: '20px',
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    '&::before': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        zIndex: -1,
                                                    },
                                                }}
                                            >
                                                <Box className="title carouseltext" sx={{ fontSize: '30px', fontWeight: 'bold' }}>
                                                    {item.title}
                                                </Box>
                                                <Box
                                                    className="subtitle carouseltext"
                                                    sx={{ fontSize: '20px', margin: '10px 0' }}
                                                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                                                />
                                                <Box className="link carouseltext" sx={{ fontSize: '16px' }}>
                                                    <a href={item.link} style={{ color: 'white', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                                                        {item.link}
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </OwlCarousel>
                            ) : (
                                <Box sx={{ display: 'grid', placeItems: 'center', height: '100%', fontSize: '22px' }}>
                                    Check Database Connection
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3.8} sx={{ paddingLeft: { xs: '0', md: '20px' }, marginTop: { xs: '20px', md: '0' } }}>
                            <Grid container justifyContent={'space-between'} height={'100%'} sx={{ gap: { xs: '20px', md: '40px' } }} >
                                <Grid item xs={5.6} md={12} className='featured-img'>
                                    <img src={FeaturedOneImg} alt="featured-one" />
                                </Grid>
                                <Grid item xs={5.6} md={12} className='featured-img'>
                                    <img src={FeaturedTwoImg} alt="featured-two" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default FeatureProducts